import "./byline.scss";

import cn from "clsx";
import { format } from "date-fns";
import {
  MediaAssetProps,
  MediaAsset,
} from "design-system/components/primitives/media-asset/media-asset";
import {
  convertToDate,
  reformatDateString,
} from "design-system/utils/date-format";
import { EditAttributes } from "design-system/types/types";
import { Link } from "design-system/components/primitives/link/link";

export interface BylinePerson {
  name: string;
  link?: string;
  avatar?: MediaAssetProps;

  editAttributes?: {
    name?: EditAttributes;
  };
}

export interface BylineProps {
  authors?: Array<BylinePerson>;
  featuredPeople?: Array<BylinePerson>;
  type?: "Authors" | "Featured" | "PeopleOnly" | "ShowAll" | "DateOnly";
  publicationDate?: string; // "YYYY-MM-DD"
  headshots?: boolean;
  disableLinks?: boolean;

  editAttributes?: {
    publicationDate?: EditAttributes;
  };
}

/**
 * ## See it in use on...
 * - An [article page](/story/example-pages-article-pages-kitchen-sink--story)
 *
 * - **`id: P-008-000-05`**
 */
export function Byline({
  authors,
  featuredPeople,
  publicationDate,
  type = "Authors",
  headshots,
  disableLinks = false,
  editAttributes,
}: BylineProps) {
  const firstAuthor = authors?.[0];
  const firstFeatured = featuredPeople?.[0];

  const formattedAuthors =
    firstAuthor &&
    firstAuthor.name?.length < 50 && // TODO: fix temperory if statement to hide really long names coming from legacy queries
    authors.map((person, i) => (
      <span
        className="hbs-byline__author"
        key={i}
        {...person.editAttributes?.name}
      >
        {person.link && !disableLinks ? (
          <Link href={person.link}>{person.name}</Link>
        ) : (
          person.name
        )}
        {i !== authors.length - 1 && (
          <>
            {authors.length !== 2 ? "," : ""}{" "}
            {authors.length > 1 && i === authors.length - 2 && "and"}{" "}
          </>
        )}
      </span>
    ));

  const formattedPeople =
    firstFeatured &&
    firstFeatured.name?.length < 50 && // TODO: fix temperory if statement to hide really long names coming from legacy queries
    featuredPeople.map((person, i) => (
      <span
        className="hbs-byline__author"
        key={i}
        {...person.editAttributes?.name}
      >
        {" "}
        {person.link && !disableLinks ? (
          <Link href={person.link}>{person.name}</Link>
        ) : (
          person.name
        )}
        {i !== featuredPeople.length - 1 && (
          <>
            {featuredPeople.length !== 2 ? "," : ""}{" "}
            {featuredPeople.length > 1 &&
              i === featuredPeople.length - 2 &&
              "and"}{" "}
          </>
        )}
      </span>
    ));

  const parsedPublicationDate = convertToDate(
    reformatDateString(publicationDate),
  );

  const formattedDate = parsedPublicationDate ? (
    <time
      dateTime={format(parsedPublicationDate, "yyyy-MM-dd")}
      {...editAttributes?.publicationDate}
    >
      {format(parsedPublicationDate, "LLLL d, yyyy")}
    </time>
  ) : null;

  const componentClass = cn(
    "hbs-byline",
    headshots && featuredPeople && "hbs-byline--headshots",
  );
  const hasHeadImage =
    featuredPeople && featuredPeople.every((person) => person.avatar);

  return (
    <div className={componentClass}>
      {type !== "DateOnly" &&
        headshots &&
        featuredPeople &&
        featuredPeople.length > 0 &&
        hasHeadImage && (
          <span className="hbs-byline__thumbnails">
            {featuredPeople.map((featuredPeople, index) => (
              <MediaAsset
                className="hbs-byline__asset"
                key={index}
                {...featuredPeople.avatar}
                hide={{ caption: true }}
              />
            ))}
          </span>
        )}
      <p className="hbs-byline__text">
        {type === "PeopleOnly" ? (
          formattedPeople
        ) : type === "DateOnly" ? (
          <>Published {formattedDate}</>
        ) : (
          <>
            {(type === "ShowAll" || type === "Featured") && formattedPeople ? (
              <>
                Featuring {formattedPeople}
                {formattedAuthors || formattedDate ? ". " : " "}
              </>
            ) : null}
            {type !== "Featured" && formattedAuthors ? (
              <>By {formattedAuthors} </>
            ) : null}
            {formattedDate ? (
              <>
                {type !== "Featured" && formattedAuthors
                  ? " on "
                  : "Published "}{" "}
                {formattedDate}
                {formattedPeople ? "." : ""}
              </>
            ) : null}
          </>
        )}
      </p>
    </div>
  );
}
